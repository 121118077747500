$('.js-require-form .required').on('input', function(e) {
  if ($(this).val()) {
    $(this).next().css('display', 'none');
    $(this).removeClass('noinput');
    if (!$('.noinput').length) {
      $('.js-require-form button').prop('disabled', '');
    }
  } else {
    $('.js-require-form button').prop('disabled', 'disabled');
    $(this).addClass('noinput');
    $(this).next().css('display', 'block');
  }
});
$('.js-require-form .required').on('blur', function(e) {
  if ($(this).val()) {
    $(this).next().css('display', 'none');
  } else {
    $(this).next().css('display', 'block');
  }
});

var queryString = window.location.search;
if (queryString.indexOf('subscribe_modal=on') > 0 && $('.subscribe-area__button.modal-open-btn')) {
  $('.subscribe-area__button.modal-open-btn').click();
};
